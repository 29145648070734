import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { requestParcels, requestCustomerToursHeader } from '../redux/actions/toursActions';
import { getUserId, getIsAdmin } from '../redux/selectors/authSelectors';
import { useTranslation } from 'react-i18next';
import Error from '../components/Error';
import { getParcels, getToursHeader } from '../redux/selectors/toursSelectors';
import MaterialTable from 'material-table';
import { Redirect } from 'react-router';
import LinkButton from '../components/LinkButton';
import { uploadRechnungToBackend, clearUploadedRechnung, uploadBundleToBackend } from '../redux/actions/filesActions';
import FileDropzone from '../components/FileDropzone';
import Button from '@material-ui/core/Button';
import { getRechnung, getBundle } from '../redux/selectors/filesSelectors';
import { snackbarError, snackbarSuccess } from '../redux/actions/snackbarActions';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    },
    loadingSpinner: {
        padding: '4px 0',
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
    },
}));

const Tours = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const customerId = useSelector(getUserId);
    const { t } = useTranslation();
    const parcels = useSelector(getParcels);
    const [redirectToDetailPage, setRedirectToDetailPage] = useState(false);
    const rechnung = useSelector(getRechnung);
    const bundle = useSelector(getBundle);
    const tours = useSelector(getToursHeader);
    const tour = tours.find(t => t.FAHRTNR == props.match.params.tourId)
    const [uploadDone, setUploadDone] = useState(false);
    const [bundleUploadDone, setBundleUploadDone] = useState(false);
    const [bundleUploading, setBundleUploading] = useState(false);
    const [filter, setFilter] = useState('open');
    const isAdmin = useSelector(getIsAdmin);

    const uploadToServer = () => {
        const data = new FormData();
        rechnung && rechnung.forEach((file) => {
            data.append('rechnung', file)
        });
        (uploadRechnungToBackend({
            files: data,
            customerId: customerId,
            tourId: props.match.params.tourId,
        }))(dispatch).then(() => {
            dispatch(requestParcels({tourId: props.match.params.tourId, customerId: customerId, isAdmin}));
            dispatch(snackbarSuccess(t('tours:uploadSuccess')))
            setUploadDone(true)
        }).catch(() => {
            dispatch(snackbarError(t('tours:uploadFailed')))
        });
    }

    const uploadBundleToServer = () => {
        setBundleUploading(true);
        const data = new FormData();
        bundle && bundle.forEach((file) => {
            data.append('bundle', file)
        });
        (uploadBundleToBackend({
            files: data,
            customerId: customerId,
            tourId: props.match.params.tourId,
        }))(dispatch).then(() => {
            dispatch(requestParcels({tourId: props.match.params.tourId, customerId: customerId, isAdmin}));
            dispatch(snackbarSuccess(t('tours:bundleUploadSuccess')))
            setBundleUploadDone(true)
        }).catch(() => {
            dispatch(snackbarError(t('tours:bundleUploadFailed')))
        }).finally(() => {
            setBundleUploading(false);
        });
    }

    useEffect(() => {
        customerId && dispatch(requestCustomerToursHeader({customerId: customerId, isAdmin}));
        customerId && dispatch(requestParcels({tourId: props.match.params.tourId, customerId: customerId, isAdmin}));
        // eslint-disable-next-line
    }, [customerId]);

    useEffect(() => {
        return () => dispatch(clearUploadedRechnung());
        // eslint-disable-next-line
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    if (customerId === undefined) {
        return <Error message={t('error:noCustomerId')}/>
    }
    
    if (redirectToDetailPage) {
        return <Redirect to={`/tours/${props.match.params.tourId}/parcel/${redirectToDetailPage}`} />
    }

    return (
        <>
            <LinkButton link={`/accounting`}>{t('actions:backToAccounting')}</LinkButton>
            
            <div>
                <h4>{t('tours:uploadBundle')}</h4>

                {(!bundleUploadDone && tour && tour.STATUS === 1) ? <>
                    <FileDropzone id={'Bundle'} title={t('documents:bundle')} files={bundle} single={true} onlyZip={true} />
                    {(bundle) && <Button variant="contained" 
                        className={styles.button}
                        color="primary" 
                        component="span"
                        disabled={bundleUploading}
                        onClick={uploadBundleToServer}>
                            {bundleUploading ? <div className={styles.loadingSpinner}><CircularProgress size='20px' /></div> : t('actions:upload')}
                    </Button>}
                    </> : <Paper className={styles.container}>
                    <Typography variant="body1">{t('tours:bundleAlreadyUploaded')}</Typography>
                </Paper>}

                <h4>{t('tours:uploadReceipt')}</h4>

                {(!uploadDone && tour && tour.STATUS === 1) ? <>
                    <FileDropzone id={'Rechnung'} title={t('documents:receipt')} files={rechnung} />
                    {(rechnung) && <Button variant="contained" 
                        className={styles.button}
                        color="primary" 
                        component="span"
                        onClick={uploadToServer}>{t('actions:upload')}
                    </Button>}
                    </> : <Paper className={styles.container}>
                    <Typography variant="body1">{t('tours:receiptAlreadyUploaded')}</Typography>
                </Paper>}
            </div>
            
            <FormControl className={styles.formControl}>
                <InputLabel id="filterTour">{t('parcels:filterParcels')}</InputLabel>
                <Select
                    value={filter}
                    onChange={handleFilterChange}>
                    <MenuItem value={'open'}>{t('status:open')}</MenuItem>
                    <MenuItem value={'done'}>{t('status:done')}</MenuItem>
                </Select>
            </FormControl>

            <MaterialTable
                columns={[
                    {title: t('parcels:id'), field: 'IDWERKLKWC'},
                    {title: t('parcels:sender'), field: 'ABSNAME'},
                    {title: t('parcels:recipient'), field: 'EMPFNAME'},
                    {title: t('parcels:pickupDate'), field: 'LADEDATUM', render: rowData => <>{new Date(rowData.LADEDATUM).toLocaleString()}</>},
                    {title: t('parcels:deliveryDate'), field: 'LIEFERDATUM', render: rowData => <>{new Date(rowData.LIEFERDATUM).toLocaleString()}</>}
                ]}
                data={parcels.filter(p => filter === 'open' ? p.STATUS !== 3 : p.STATUS === 3)}
                title={t('parcels:parcels')}
                options={{
                    headerStyle: {
                        paddingLeft: 16
                    }}
                }
                actions={[{
                        icon: 'search',
                        tooltip: 'Details',
                        onClick: (event, rowData) => {
                            setRedirectToDetailPage(rowData['IDWERKLKWC']);
                        }
                    }]} 
            />

        </>
    )
}

export default Tours;