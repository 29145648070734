import React from 'react';
import logo from '../../assets/logo.png';
import { Img } from './style';

const Logo = () => {
    return (
        <Img src={logo} alt='Alpensped Logo'/>
    )
};

export default Logo;
