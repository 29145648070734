import { 
    PARCELS_REQUESTED, 
    PARCELS_ERROR, 
    PARCELS_RECEIVED,
    CUSTOMER_TOURS_HEADER_ERROR,
    CUSTOMER_TOURS_HEADER_RECEIVED,
    CUSTOMER_TOURS_HEADER_REQUESTED
 } from '../actionTypes';
import axios from 'axios';

export const requestCustomerToursHeader = ({customerId, isAdmin}) => {
    return dispatch => {
        dispatch({
            type: CUSTOMER_TOURS_HEADER_REQUESTED,
            payload: customerId
        });

        axios.post(process.env.REACT_APP_API_URL + '/tours-header', {customerId, isAdmin})
            .then((response) => {
                dispatch({type: CUSTOMER_TOURS_HEADER_RECEIVED, payload: response.data});
            })
            .catch(error => dispatch({type: CUSTOMER_TOURS_HEADER_ERROR, payload: error}));
    }
}

export const requestParcels = ({tourId, customerId, isAdmin}) => {
    return dispatch => {
        dispatch({
            type: PARCELS_REQUESTED,
            payload: tourId
        });

        axios.post(process.env.REACT_APP_API_URL + '/tour', {tourId, customerId, isAdmin})
            .then((response) => dispatch({type: PARCELS_RECEIVED, payload: response.data}))
            .catch(error => dispatch({type: PARCELS_ERROR, payload: error}));
    }
}
