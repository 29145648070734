import React from 'react';
import { useSelector } from 'react-redux';
import {
    getSalutation,
    getFirstName,
    getLastName,
    getCompanyName,
    getCountry,
    getPlz,
    getCity,
    getEmail,
    getPhone,
 } from '../../redux/selectors/frightInquirySelectors';
 import {
    updateSalutation,
    updateFirstName,
    updateLastName,
    updateCompanyName,
    updateCountry,
    updatePlz,
    updateCity,
    updateEmail,
    updatePhone,
 } from '../../redux/actions/frightInquiryActions';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { countries } from '../../helpers/countries';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        minWidth: 200
    },
    formGroup: {
        // maxWidth: '761px'
    }
}));

const ContactData = () => {
    const { t } = useTranslation()
    const classes = useStyles();
    const salutation = useSelector(getSalutation);
    const firstName = useSelector(getFirstName);
    const lastName = useSelector(getLastName);
    const companyName = useSelector(getCompanyName);
    const country = useSelector(getCountry);
    const plz = useSelector(getPlz);
    const city = useSelector(getCity);
    const email = useSelector(getEmail);
    const phone = useSelector(getPhone);
    const dispatch = useDispatch();

    const handleSalutationChange = event => dispatch(updateSalutation(event.target.value));
    const handleFirstNameChange = event => dispatch(updateFirstName(event.target.value));
    const handleLastNameChange = event => dispatch(updateLastName(event.target.value));
    const handleCompanyNameChange = event => dispatch(updateCompanyName(event.target.value));
    const handleCountryChange = event => dispatch(updateCountry(event.target.value));
    const handlePlzChange = event => dispatch(updatePlz(event.target.value));
    const handleCityChange = event => dispatch(updateCity(event.target.value));
    const handleEmailChange = event => dispatch(updateEmail(event.target.value));
    const handlePhoneChange = event => dispatch(updatePhone(event.target.value));

    return <span>
        <FormGroup row className={classes.formGroup}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth required>
                <InputLabel id="salutation">{t('fiContactData:salutation')}</InputLabel>
                <Select
                    className={classes.formElement}
                    id="salutation"
                    value={salutation}
                    onChange={handleSalutationChange}
                    label="salutation"
                    labelWidth={100}
                >
                    <MenuItem value={'Herr'} key={'m'}>{t('fiContactData:m')}</MenuItem>
                    <MenuItem value={'Frau'} key={'f'}>{t('fiContactData:f')}</MenuItem>
                </Select>
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField variant="outlined" label={t('fiContactData:firstName')} value={firstName} onChange={handleFirstNameChange} required />    
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField variant="outlined" label={t('fiContactData:lastName')} value={lastName} onChange={handleLastNameChange} required />    
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField variant="outlined" label={t('fiContactData:companyName')} value={companyName} onChange={handleCompanyNameChange} required />    
            </FormControl>
        </FormGroup>

        <FormGroup row>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="countryTo">{t('fi:country')}</InputLabel>
                <Select
                    id="country"
                    value={country}
                    onChange={handleCountryChange}
                    label="country"
                    labelWidth={56}
                >
                    {countries.map(c => <MenuItem value={c} key={c}>{c}</MenuItem>)}
                </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
                <TextField variant="outlined" label={t('fi:plz')} value={plz} onChange={handlePlzChange} />    
            </FormControl>
            
            <FormControl className={classes.formControl}>
                <TextField variant="outlined" label={t('fi:city')} value={city} onChange={handleCityChange} />    
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField variant="outlined" label={t('fiContactData:email')} value={email} onChange={handleEmailChange} required />    
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField variant="outlined" label={t('fiContactData:phone')} value={phone} onChange={handlePhoneChange} required />    
            </FormControl>
        </FormGroup>
    </span>
}

export default ContactData;