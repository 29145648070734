import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Menu from './Menu';
import { createBrowserHistory } from 'history';
import CssBaseline from '@material-ui/core/CssBaseline';
import Home from '../pages/Home';
import { HashRouter, Route, Switch } from "react-router-dom";
import YouAreLost from './YouAreLost';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';
import { appStarted } from '../redux/actions/appActions';
import { routeChanged, historyCreated } from '../redux/actions/navActions';
import { requestBoundFilesRequirements } from '../redux/actions/filesActions';
import Accounting from '../pages/Accounting';
import Tour from '../pages/Tour';
import Parcel from '../pages/Parcel';
import Imprint from '../pages/Imprint';
import DataPolicy from '../pages/DataPolicy';
import GlobalSnackbar from "../components/Snackbar";
import UserManagement from '../pages/UserManagement';
import PrivateRoute from './PrivateRoute';

let history = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {    
    dispatch(appStarted());
    dispatch(historyCreated(history));
    dispatch(routeChanged(history.location.hash));
    dispatch(requestBoundFilesRequirements());

    history.listen(() => {
      dispatch(routeChanged(history.location.hash));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CssBaseline/>
      <ThemeProvider theme={theme}>
        <HashRouter history={history}>
          <Menu>
            <Switch>
                <Route
                  exact
                  path={'/'}
                  component={Home}
                  key={'home'} />

                <Route
                  exact
                  path={'/accounting'}
                  component={Accounting}
                  key={'accounting'} />

                <Route
                  exact
                  path={'/tours/:tourId'}
                  component={Tour}
                  key={'tour'} />

                <Route
                  exact
                  path={'/tours/:tourId/parcel/:parcelId'}
                  component={Parcel}
                  key={'parcel'} />

                <Route
                  exact
                  path={'/imprint'}
                  component={Imprint}
                  key={'imprint'} />

                <Route
                  exact
                  path={'/dataPolicy'}
                  component={DataPolicy}
                  key={'dataPolicy'} />
                  
                <PrivateRoute
                  exact
                  path={'/userManagement'}
                  component={UserManagement}
                  key={'userManagement'} />
                    
                <Route component={YouAreLost} />
            </Switch>
          </Menu>
          <GlobalSnackbar />
        </HashRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
