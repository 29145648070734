import { ROUTE_CHANGED, HISTORY_CREATED } from '../actionTypes';

const initialState = {
    error: false,
    currentPath: '',
    history: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ROUTE_CHANGED: {
            state.currentPath = action.payload;
            return state;
        }

        case HISTORY_CREATED: {
            state.history = action.payload;
            return state;
        }

        default: {
            return state;
        }
    }
}