import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logIn } from '../../redux/actions/authActions';
import { getLoginError } from '../../redux/selectors/authSelectors';
import { Typography } from '@material-ui/core';
import PaperSheet from '../Paper';
import Cookies from 'js-cookie';
import Logo from '../Logo';
import { useTranslation } from 'react-i18next';
import background from '../../assets/background.jpg';
import xss from 'xss';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
          margin: theme.spacing(1) + 'px 0',
          width: '300px',
        },
    },
    container: {
        width: '300px',
        margin: '0 auto',
    },
    paper: {
        padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
        margin: '0 auto',
        width: '380px'
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(4),
    },
    wrapper: {
        padding: theme.spacing(4),
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(2)
    },
    error: {
        marginTop: theme.spacing(1),
        fontSize: '14px'
    }
}));

const Login = () => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const error = useSelector(getLoginError);
    const { t } = useTranslation();

    const handleUsernameChange = (e) => {
        setUsername(xss(e.target.value));
    }

    const handlePasswordChange = (e) => {
        setPassword(xss(e.target.value));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        logIn(username, password)(dispatch).then(result => {
            Cookies.set('userId', result.userId, {
                expires: 30
            })
            Cookies.set('username', result.username, {
                expires: 30
            })
            window.location.href = process.env.REACT_APP_BASE_URL;
        });
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.logo}>
                <Logo/>
            </div>
            <PaperSheet className={classes.paper}>
                <div className={classes.container}>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <TextField
                            id="username"
                            label={t('login:username')}
                            variant="outlined"
                            value={username}
                            onChange={handleUsernameChange}
                            type="text"
                        />
                        <TextField
                            id="password"
                            label={t('login:password')}
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            disabled={!username || !password}
                            variant="contained"
                            type="submit"
                            className={classes.button}>{t('login:login')}</Button>
                    </form>
                    <Typography color={"error"} className={classes.error}>{ error && t('login:validationError')}</Typography>
                </div>
            </PaperSheet>
        </div>
    )
}

export default Login;