export const countries = [
    'Deutschland',
    'Andorra',
    'Bosnien und Herzegowina',
    'Dänemark',
    'Finnland',
    'Griechenland',
    'Irland',
    'Italien',
    'Kroatien',
    'Liechtenstein',
    'Luxemburg',
    'Mazedonien',
    'Montenegro',
    'Niederlande',
    'Österreich',
    'Portugal',
    'Russland',
    'Schweden',
    'Serbien',
    'Slowenien',
    'Tschechien',
    'Ungarn',
    'Weißrussland',
    'Türkei',
    'Albanien',
    'Belgien',
    'Bulgarien',
    'Estland',
    'Frankreich',
    'Großbritannien und Nordirland',
    'Island',
    'Kosovo',
    'Lettland',
    'Litauen',
    'Malta',
    'Moldawien',
    'Monaco',
    'Norwegen',
    'Polen',
    'Rumänien',
    'San Marino',
    'Schweiz',
    'Slowakei',
    'Spanien',
    'Ukraine',
    'Vatikanstadt',
    'Kasachstan'
];