import { 
    LOGIN_SUCCESS, 
    LOGIN_ERROR,
    LOGOUT,
    CREATE_USER_ERROR,
    AUTHENTICATION_ERROR,
    AUTHENTICATION_SUCCESS,
    USERS_RECEIVED,
 } from "../actionTypes";

const initialState = {
    loginError: false,
    createUserError: false,
    userId: '',
    username: '',
    authenticated: false,
    isAdmin: false,
    users: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            state.userId = action.payload.userId;
            return state;
        }

        case LOGIN_ERROR: {
            state.loginError = true;
            return state;
        }

        case CREATE_USER_ERROR: {
            state.createUserError = true;
            return state;
        }

        case LOGOUT: {
            state.userId = '';
            return state;
        }

        case AUTHENTICATION_ERROR: {
            state.authenticated = false;
            return state;
        }

        case AUTHENTICATION_SUCCESS: {
            return {
                ...state,
                authenticated: true,
                ...action.payload
            }
        }

        case USERS_RECEIVED: {
            state.users = action.payload;
            return state;
        }

        default: {
            return state;
        }
    }
}