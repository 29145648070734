import {SNACKBAR_SUCCESS, SNACKBAR_ERROR, SNACKBAR_MSG_DISPLAYED, SNACKBAR_MSG, SNACKBAR_INFO} from "../actionTypes";

const initialState = {
    snackbar: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SNACKBAR_SUCCESS:
        case SNACKBAR_ERROR:
        case SNACKBAR_MSG:
        case SNACKBAR_INFO: {
            const snackbarState = state.snackbar ? [...state.snackbar, action.payload] : [action.payload]
            return {
                ...state,
                snackbar: snackbarState
            };
        }
        case SNACKBAR_MSG_DISPLAYED:
            return state.snackbar = state.snackbar ? state.snackbar.filter(sMsg => sMsg.uid !== action.payload.uid) : [];
        default:
            return state;
    }
};