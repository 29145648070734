import React from 'react';

const Claim = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    if ((String(day)).length === 1) day = '0' + day;
    if ((String(month)).length === 1) month = '0' + month;
    const dateT = day + '.' + month + '.' + date.getFullYear();
    
    return (
        <div>
            <p>Copyright {dateT} <a href='https://alpensped.de/' target='blank'>Alpensped</a></p>
        </div>
    )
};

export default Claim;
