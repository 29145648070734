import {SNACKBAR_ERROR, SNACKBAR_INFO, SNACKBAR_MSG, SNACKBAR_MSG_DISPLAYED, SNACKBAR_SUCCESS} from "../actionTypes";
import * as uuid from "uuid";

export const snackbarMsg = (msg, type) => {
    const uid = uuid.v4();
    return dispatch => {
        dispatch({
            type: SNACKBAR_MSG,
            payload: {msg, uid, type}
        })
    }
};

export const snackbarSuccess = (msg) => {
    const uid = uuid.v4();
    const type = 'success';
    return dispatch => {
        dispatch({
            type: SNACKBAR_SUCCESS,
            payload: {msg, uid, type}
        })
    }
};

export const snackbarError = (msg) => {
    const uid = uuid.v4();
    const type = 'error';
    return dispatch => {
        dispatch({
            type: SNACKBAR_ERROR,
            payload: {msg, uid, type}
        })
    }

};

export const snackbarInfo = (msg) => {
    const uid = uuid.v4();
    const type = 'info';
    return dispatch => {
        dispatch({
            type: SNACKBAR_INFO,
            payload: {msg, uid, type}
        })
    }
}

export const snackbarMsgDisplayed = (uid) => {
    return dispatch => {
        dispatch({
            type: SNACKBAR_MSG_DISPLAYED,
            payload: {uid}
        })
    }
}