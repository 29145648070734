import { 
    CUSTOMER_TOURS_HEADER_ERROR,
    CUSTOMER_TOURS_HEADER_RECEIVED,
    PARCELS_ERROR,
    PARCELS_RECEIVED,
 } from '../actionTypes';

const initialState = {
    error: false,
    toursHeader: [],
    parcels: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_TOURS_HEADER_ERROR:
        case PARCELS_ERROR: {
            state.error = true;
            return state;
        }

        case CUSTOMER_TOURS_HEADER_RECEIVED: {
            state.error = false;
            state.toursHeader = action.payload;
            return state;
        }

        case PARCELS_RECEIVED: {
            state.parcels = action.payload;
            return state;
        }

        default: {
            return state;
        }
    }
}