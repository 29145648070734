import { 
    RECHNUNG_UPLOADED, 
    FRACHTBRIEF_UPLOADED, 
    LIEFERSCHEIN_UPLOADED, 
    LAUFZETTEL_UPLOADED,
    FILES_UPLOADED_TO_BACKEND,
    FILES_UPLOADED_TO_BACKEND_ERROR,
    FILES_UPLOADED_TO_BACKEND_SUCCESS,
    BOUND_FILES_REQUIREMENTS_ERROR,
    BOUND_FILES_REQUIREMENTS_REQUESTED,
    BOUND_FILES_REQUIREMENTS_SUCCESS,
    CLEAR_UPLOADED_FILES,
    RECHNUNG_UPLOADED_TO_BACKEND,
    RECHNUNG_UPLOADED_TO_BACKEND_ERROR,
    RECHNUNG_UPLOADED_TO_BACKEND_SUCCESS,
    CLEAR_UPLOADED_RECHNUNG,
    BUNDLE_UPLOADED,
    BUNDLE_UPLOADED_TO_BACKEND,
    BUNDLE_UPLOADED_TO_BACKEND_SUCCESS,
    BUNDLE_UPLOADED_TO_BACKEND_ERROR,
} from '../actionTypes';
import axios from 'axios';

export const upload = (files, type) => {
    return dispatch => {
        switch (type) {
            case 'Rechnung': {
                dispatch({type: RECHNUNG_UPLOADED, payload: files});
                break;
            }

            case 'Frachtbrief': {
                dispatch({type: FRACHTBRIEF_UPLOADED, payload: files});
                break;
            }

            case 'Lieferschein': {
                dispatch({type: LIEFERSCHEIN_UPLOADED, payload: files});
                break;
            }

            case 'Laufzettel': {
                dispatch({type: LAUFZETTEL_UPLOADED, payload: files});
                break;
            }

            case 'Bundle': {
                dispatch({type: BUNDLE_UPLOADED, payload: files});
                break;
            }

            default: return;
        }
    }
};

export const uploadBundleToBackend = ({files, customerId, tourId}) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: BUNDLE_UPLOADED_TO_BACKEND, 
                payload: {
                    files: files, 
                    customerId: customerId, 
                    tourId: tourId, 
                }
            })
    
            axios.post(`${process.env.REACT_APP_API_URL}/uploadBundle/${customerId}/${tourId}`, files)
                .then((response) => {
                    dispatch({
                        type: BUNDLE_UPLOADED_TO_BACKEND_SUCCESS, 
                        payload: {
                            files: files, 
                            customerId: customerId, 
                            tourId: tourId, 
                        }
                    })
                    resolve(response);
                })
                .catch(error => {
                    dispatch({type: BUNDLE_UPLOADED_TO_BACKEND_ERROR, payload: {error}})
                    reject();
                });
        })
    }
}

export const uploadRechnungToBackend = ({files, customerId, tourId}) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: RECHNUNG_UPLOADED_TO_BACKEND, 
                payload: {
                    files: files, 
                    customerId: customerId, 
                    tourId: tourId, 
                }
            })
    
            axios.post(`${process.env.REACT_APP_API_URL}/uploadRechnung/${customerId}/${tourId}`, files)
                .then((response) => {
                    dispatch({
                        type: RECHNUNG_UPLOADED_TO_BACKEND_SUCCESS, 
                        payload: {
                            files: files, 
                            customerId: customerId, 
                            tourId: tourId, 
                        }
                    })
                    resolve(response);
                })
                .catch(error => {
                    dispatch({type: RECHNUNG_UPLOADED_TO_BACKEND_ERROR, payload: {error}})
                    reject();
                });
        })
    }
}

export const uploadFilesToBackend = ({files, customerId, tourId, parcelId}) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FILES_UPLOADED_TO_BACKEND, 
                payload: {
                    files: files, 
                    customerId: customerId, 
                    tourId: tourId, 
                    parcelId: parcelId
                }
            })
    
            axios.post(`${process.env.REACT_APP_API_URL}/upload/${customerId}/${tourId}/${parcelId}`, files)
                .then((response) => {
                    dispatch({
                        type: FILES_UPLOADED_TO_BACKEND_SUCCESS, 
                        payload: {
                            files: files, 
                            customerId: customerId, 
                            tourId: tourId, 
                            parcelId: parcelId
                        }
                    })
                    resolve(response);
                })
                .catch(error => {
                    dispatch({type: FILES_UPLOADED_TO_BACKEND_ERROR, payload: {error}})
                    reject();
                });
        })
    }
}

export const requestBoundFilesRequirements = () => {
    return dispatch => {
        dispatch({
            type: BOUND_FILES_REQUIREMENTS_REQUESTED,
            payload: {}
        });

        axios.get(`${process.env.REACT_APP_API_URL}/bound-files-requirements`)
            .then(response => {
                dispatch({
                    type: BOUND_FILES_REQUIREMENTS_SUCCESS,
                    payload: response.data
                })
            })
            .catch(error => {
                dispatch({type: BOUND_FILES_REQUIREMENTS_ERROR, payload: {error}})
            })
    }
}

export const clearUploadedFiles = () => dispatch => dispatch({type: CLEAR_UPLOADED_FILES});

export const clearUploadedRechnung = () => dispatch => dispatch({type: CLEAR_UPLOADED_RECHNUNG})