import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
    value: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    },
    showDataButton: {
        marginBottom: theme.spacing(1)
    }
}));

const Overview = (props) => {
    const FID = props.fid;
    const { t } = useTranslation('fiDataCheck');
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    return <span>
        <Button className={classes.showDataButton} color='primary' onClick={() => setExpanded(!expanded)}>{t('showData')}</Button>
        <Collapse in={expanded}>
            {Object.entries(FID).map(([key, value]) => {
                if (key === 'error' || key === 'newFrightInquiryId' || !value) return <></>
                return <div className={classes.value}>
                    <Typography variant='caption'>{t(key)}</Typography>
                    <Typography variant='body1'>{value === true ? '+' : value === false ? '-' : value}</Typography>
                </div>
            })}
        </Collapse>
        
    </span>
}

export default Overview;