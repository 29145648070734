import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

let middleware = applyMiddleware(ReduxThunk);

const store = createStore(
    rootReducer,
    composeWithDevTools(middleware)
);

export default store;
