import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';

const overrides = {
  MuiTabs: {
    indicator: {
      backgroundColor: palette.common.white,
    },
  },
};

let theme = createMuiTheme({
  overrides,
  palette,
  typography: typography
});

export const drawerWidth = 240;

export default responsiveFontSizes(theme);
