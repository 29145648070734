export const getRechnung = (state) =>
    state.Files.rechnung;

export const getFrachtbrief = (state) =>
    state.Files.frachtbrief;

export const getLieferschein = (state) =>
    state.Files.lieferschein;

export const getLaufzettel = (state) =>
    state.Files.laufzettel;

export const getBoundFilesRequirements = (state) =>
    state.Files.boundFilesRequirements;

export const getBundle = (state) => 
    state.Files.bundle;