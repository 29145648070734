import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  marginTop: {
    marginTop: theme.spacing(3),
  }
}));

const PaperSheet = props => {
  const {children} = props;
  const classes = useStyles();

  return (
      <Paper className={`${classes.root} ${props.margintop && classes.marginTop}`} component="section" {...props}> 
          {children}
      </Paper>
  );
};

export default PaperSheet;