import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
    getSendungsart,
    getWeight,
    getDescription,
    getFrequency,
    getFrequencyAdditionalInfo,
    getVehicleType,
    getSidenote,
} from '../../redux/selectors/frightInquirySelectors';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';
import {
    updateSendungsart,
    updateWeight,
    updateDescription,
    updateFrequency,
    updateFrequencyAdditionalInfo,
    updateVehicleType,
    updateSidenote
} from '../../redux/actions/frightInquiryActions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

const sendungsarten = ['parts', 'complete', 'heavySpecial'];
const frequencies = ['once', 'daily', 'weekly', 'monthly', 'ausschreibung'];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3),
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        minWidth: 400
    },
    formGroup: {
        maxWidth: '761px'
    }
}));

const Description = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const sendungsart = useSelector(getSendungsart);
    const weight = useSelector(getWeight);
    const description = useSelector(getDescription);
    const frequency = useSelector(getFrequency);
    const frequencyAdditionalInfo = useSelector(getFrequencyAdditionalInfo);
    const vehicleType = useSelector(getVehicleType);
    const sidenote = useSelector(getSidenote);

    const handleSendungsartChange = event => dispatch(updateSendungsart(event.target.value));
    const handleWeightChange = event => dispatch(updateWeight(event.target.value));
    const handleDescriptionChange = event => dispatch(updateDescription(event.target.value));
    const handleFrequencyChange = event => dispatch(updateFrequency(event.target.value));
    const handleFrequencyAdditionalInfoChange = event => dispatch(updateFrequencyAdditionalInfo(event.target.value));
    const handleVehicleTypeChange = event => dispatch(updateVehicleType(event.target.value));
    const handleSidenoteChange = event => dispatch(updateSidenote(event.target.value));

    return <div className={classes.root}>
        <FormGroup row className={classes.formGroup}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth required>
                <InputLabel id="sendungsart">{t('fiDescription:type')}</InputLabel>
                <Select
                    className={classes.formElement}
                    id="sendungsart"
                    value={sendungsart}
                    onChange={handleSendungsartChange}
                    label="sendungsart"
                    labelWidth={100}
                >
                    {sendungsarten.map(art => <MenuItem value={art} key={art}>{t(`fiDescription:${art}`)}</MenuItem>)}
                </Select>
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField type="number" variant="outlined" label={t('fiDescription:weight')} value={weight} onChange={handleWeightChange} required />    
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField multiline={true} rows={5} variant="outlined" label={t('fiDescription:description')} value={description} required onChange={handleDescriptionChange} />  
                <FormHelperText>{t('fiDescription:descriptionHelpText')}</FormHelperText>  
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel id="sendungsart">{t('fiDescription:frequency')}</InputLabel>
                <Select
                    className={classes.formElement}
                    id="frequency"
                    value={frequency}
                    onChange={handleFrequencyChange}
                    label="frequency"
                    labelWidth={100}
                >
                    {frequencies.map(f => <MenuItem value={f} key={f}>{t(`fiDescription:${f}`)}</MenuItem>)}
                </Select>
            </FormControl>
        </FormGroup>

        {(frequency === 'daily' || frequency === 'weekly' || frequency === 'monthly') &&
            <FormGroup row className={classes.formGroup}>
                <FormControl className={classes.formControl} fullWidth>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t('fiDescription:frequencyHelpText')}
                        value={frequencyAdditionalInfo}
                        onChange={handleFrequencyAdditionalInfoChange}
                    />    
                </FormControl>
            </FormGroup>
        }

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField 
                    variant="outlined"
                    label={t('fiDescription:vehicleType')}
                    value={vehicleType}
                    onChange={handleVehicleTypeChange}
                />    
            </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formGroup}>
            <FormControl className={classes.formControl} fullWidth>
                <TextField multiline={true} rows={5} variant="outlined" label={t('fiDescription:sidenote')} value={sidenote} onChange={handleSidenoteChange} />  
            </FormControl>
        </FormGroup>
    </div>
}

export default Description;