import React, { useState, useCallback, useEffect } from 'react';
import {useDropzone} from 'react-dropzone'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InsertDriveFileOutline from '@material-ui/icons/InsertDriveFileOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { humanReadableFileSize } from '../../helpers/humanReadableFileSize';
import { useDispatch } from 'react-redux';
import { upload } from '../../redux/actions/filesActions';
import { useTranslation } from 'react-i18next';
import PdfPreview from '../../components/PdfPreview';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        outline: 'none'
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(1)
    },
    paper: {
        marginBottom: theme.spacing(2)
    }
}));

const FileDropzone = (props) => {
    const [uploadedFiles, setUploadedFiles] = useState();
    const onDrop = useCallback(acceptedFiles => {
        setUploadedFiles(acceptedFiles);
        // eslint-disable-next-line
    }, [])
    const dropConfig = {
        onDrop,
        multiple: props.single ? !props.single : true,
    }
    if (props.onlyZip) dropConfig.accept = ".zip";
    const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone(dropConfig);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        uploadedFiles && dispatch(upload(uploadedFiles, props.id));
        // eslint-disable-next-line
    }, [uploadedFiles]);

    return (
        <Paper component="section" className={classes.paper}>
            <div {...getRootProps()} className={classes.root}>
                <Typography variant="h4">
                    <div className={classes.header}>
                        {props.title}
                        <InsertDriveFileOutline fontSize='large'/>
                    </div>
                </Typography>
                <div className={classes.content}>
                    <input {...getInputProps()}/>
                    {   
                        acceptedFiles.length > 0 ?
                        <div>
                            <Typography variant="h6">
                                {uploadedFiles && `${uploadedFiles.length > 1 ? t('uploadField:yourFiles') : t('uploadField:yourFile')}:` }
                            </Typography>
                            {uploadedFiles && uploadedFiles.map(file => <Typography key={file.name} variant="h6">
                                {file.name} {file.name.endsWith('.pdf') && <PdfPreview pdf={file}/>} 
                            </Typography>)}
                            <Typography variant="h6">
                                {uploadedFiles && `${t('uploadField:size')}: ` + humanReadableFileSize(uploadedFiles.reduce((total, file) => total + file.size, 0))}
                            </Typography>
                            <Typography variant="caption">
                                {t('uploadField:uploadOther')}
                            </Typography>
                        </div> :
                        isDragActive ?
                        <Typography variant="body1">
                            {t('uploadField:dropHere')}
                        </Typography> :
                        <Typography variant="body1">
                            {t('uploadField:idleInfo')}
                        </Typography>
                    }
                </div>
            </div>
        </Paper>
    )
}

export default FileDropzone;