import { ROUTE_CHANGED, HISTORY_CREATED } from '../actionTypes';

export const routeChanged = currentPath => {
    return dispatch => {
        dispatch({
            type: ROUTE_CHANGED, 
            payload: currentPath
        });
    }
};

export const historyCreated = history => {
    return dispatch => {
        dispatch({
            type: HISTORY_CREATED,
            payload: history
        })
    }
}
