import axios from 'axios';
import {
    HOME_CONTENT_REQUESTED,
    HOME_CONTENT_RECEIVED,
    HOME_CONTENT_ERROR,
    IMPRINT_CONTENT_REQUESTED,
    IMPRINT_CONTENT_RECEIVED,
    IMPRINT_CONTENT_ERROR,
    DATA_POLICY_CONTENT_REQUESTED,
    DATA_POLICY_CONTENT_RECEIVED,
    DATA_POLICY_CONTENT_ERROR
} from '../actionTypes';

export const requestHomeContent = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: HOME_CONTENT_REQUESTED, payload: {}});

            axios.get(process.env.REACT_APP_API_URL + '/home-content')
                .then((response) => {
                    dispatch({type: HOME_CONTENT_RECEIVED, payload: response.data});
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    dispatch({type: HOME_CONTENT_ERROR, payload: error});
                    reject(error);
                });
        });
    }
};

export const requestImprintContent = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: IMPRINT_CONTENT_REQUESTED, payload: {}});

            axios.get(process.env.REACT_APP_API_URL + '/imprint-content')
                .then((response) => {
                    dispatch({type: IMPRINT_CONTENT_RECEIVED, payload: response.data});
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    dispatch({type: IMPRINT_CONTENT_ERROR, payload: error});
                    reject(error);
                });
        });
    }
};

export const requestDataPolicyContent = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: DATA_POLICY_CONTENT_REQUESTED, payload: {}});

            axios.get(process.env.REACT_APP_API_URL + '/data-policy-content')
                .then((response) => {
                    dispatch({type: DATA_POLICY_CONTENT_RECEIVED, payload: response.data});
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    dispatch({type: DATA_POLICY_CONTENT_ERROR, payload: error});
                    reject(error);
                });
        });
    }
};