import { APP_STARTED } from '../actionTypes';

export const appStarted = () => {
    return dispatch => {
        dispatch({
            type: APP_STARTED,
            payload: {
                env: process.env.REACT_APP_ENV,
                baseUrl: process.env.REACT_APP_BASE_URL,
                apiUrl: process.env.REACT_APP_API_URL            
            }
        });
    }
}
