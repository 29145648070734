import {
    HOME_CONTENT_RECEIVED,
    HOME_CONTENT_ERROR,
    IMPRINT_CONTENT_RECEIVED,
    IMPRINT_CONTENT_ERROR,
    DATA_POLICY_CONTENT_RECEIVED,
    DATA_POLICY_CONTENT_ERROR
} from '../actionTypes';

const initialState = {
    home: {
        content: '',
        error: false
    },
    imprint: {
        content: '',
        error: false
    },
    dataPolicy: {
        content: '',
        error: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_POLICY_CONTENT_ERROR:{
            state.dataPolicy.error = true;
            return state;
        }

        case IMPRINT_CONTENT_ERROR:{
            state.imprint.error = true;
            return state;
        }

        case HOME_CONTENT_ERROR:{
            state.home.error = true;
            return state;
        }

        case HOME_CONTENT_RECEIVED: {
            state.home.error = false;
            state.home.content = action.payload;
            return state;
        }

        case DATA_POLICY_CONTENT_RECEIVED: {
            state.dataPolicy.error = false;
            state.dataPolicy.content = action.payload;
            return state;
        }

        case IMPRINT_CONTENT_RECEIVED: {
            state.imprint.error = false;
            state.imprint.content = action.payload;
            return state;
        }

        default: {
            return state;
        }
    }
}