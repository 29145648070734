import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getUsername } from '../redux/selectors/authSelectors';
import { getHomeContent } from '../redux/selectors/contentSelectors';
import { requestHomeContent } from '../redux/actions/contentActions';
import { useSelector, useDispatch } from 'react-redux';
import PaperSheet from '../components/Paper';

const Home = () => {
    const { t } = useTranslation();
    const username = useSelector(getUsername);
    const content = useSelector(getHomeContent);
    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        requestHomeContent()(dispatch).then(() => setDataLoaded(true))
        // eslint-disable-next-line
    }, []);
    
    return (
        <>
            <Typography variant="h3">
                {t('home:welcome')}
            </Typography>
            <Typography variant="body1">
                {t('home:loggedInAs')} {username}.
            </Typography>

            {(dataLoaded && content.content.length > 0) && <PaperSheet margintop="true">
                <div dangerouslySetInnerHTML={{__html: content.content[0].CONTENT}}/>
            </PaperSheet>}
        </>
    )
}

export default Home;