import React, { useEffect, useState } from 'react';
import PaperSheet from '../components/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getCreateUserError, getUsers } from '../redux/selectors/authSelectors';
import { createUser, getAllUsers, deleteUser } from '../redux/actions/authActions';
import MaterialTable from 'material-table';
import { snackbarSuccess, snackbarError } from '../redux/actions/snackbarActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1) + 'px 0',
            width: '300px',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1)
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    error: {
        marginTop: theme.spacing(1)
    }
}));

const UserManagement = () => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userId, setUserId] = useState('');
    const dispatch = useDispatch();
    const error = useSelector(getCreateUserError);
    const users = useSelector(getUsers);
    const { t } = useTranslation();

    useEffect(() => {
        users.length < 1 && dispatch(getAllUsers());
        // eslint-disable-next-line
    }, []);

    const handleSubmit = () => {
        createUser(username, password, userId)(dispatch).then(response => {
            setUsername('');
            setPassword('');
            setUserId('');
            dispatch(snackbarSuccess(t('userManagement:creationSuccess', {username: username})));
            dispatch(getAllUsers());
        })
    }

    return (
        <>
            <PaperSheet className={classes.paper}>
                <div className={classes.container}>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <TextField
                            id="username"
                            label={t('login:username')}
                            variant="outlined"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                        <TextField
                            id="password"
                            label={t('login:password')}
                            variant="outlined"
                            value={password}
                            onChange={e => setPassword(e.target.value)}                    
                        />
                        <TextField
                            id="userId"
                            label={t('userManagement:userId')}
                            variant="outlined"
                            value={userId}
                            onChange={e => setUserId(e.target.value)}                    
                        />
                    </form>
                    <Button 
                        className={classes.button}
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!username || !password || !userId}
                        variant="contained">{t('userManagement:createUser')}</Button>
                    { error && <Typography color={"error"} className={classes.error}>{t('userManagement:creationFailed')}</Typography>}
                </div>
            </PaperSheet>
           
            <MaterialTable
                columns={[
                    {title: t('login:username'), field: 'username'},
                    {title: t('login:password'), field: 'password'},
                    {title: t('userManagement:userId'), field: 'userId'},
                    {title: t('userManagement:role'), field: 'role'},
                ]}
                data={users}
                title={t('userManagement:allUsers')}
                options={{
                    headerStyle: {
                        paddingLeft: 16
                    }}
                }
                actions={[
                    rowData => ({
                        icon: 'delete',
                        tooltip: t('userManagement:deleteUser'),
                        onClick: (event, rowData) => {
                            if (window.confirm(t('userManagement:deleteConfirm', {username: rowData.username}))) {
                                deleteUser(rowData.username, rowData.userId)(dispatch).then(response => {
                                    if (response.deleted) {
                                        dispatch(snackbarSuccess(t('userManagement:deleteSuccess', {username: rowData.username})))
                                        dispatch(getAllUsers());
                                    } else {
                                        dispatch(snackbarError(t('userManagement:deleteFailed', {username: rowData.username})))
                                    }
                                });
                            }
                        },
                        disabled: rowData.role === 'admin'
                    })
                ]} 
            />
        </>
    )
}

export default UserManagement;