import { APP_STARTED } from '../actionTypes';

const initialState = {
    error: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case APP_STARTED: {
            return {
                ...state,
                ...action.payload
            }
        }

        default: {
            return state;
        }
    }
}