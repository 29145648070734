import styled from 'styled-components';

const Img = styled.img`
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
`;

export {
    Img
}
