import React from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Logo from "../Logo";
import { Link } from "react-router-dom";
import { drawerWidth } from '../../theme';
import LogOutButton from '../LogoutButton';
import LanguageSwitcher from '../LanguageSwitcher';
import Claim from '../Claim';
import { getCurrentPath } from '../../redux/selectors/navSelectors';
import { useTranslation } from 'react-i18next';
import { getIsAdmin } from '../../redux/selectors/authSelectors';

const useStyles = makeStyles(theme => 
    createStyles({
        sidebar: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        grow: {
            flexGrow: 1,
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.up('md')]: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        appBarFooter: {
            top: 'auto',
            bottom: 0,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            padding: theme.spacing(2, 3, 8, 3),
            [theme.breakpoints.up('md')]: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        link: {
            display: 'flex',
            color: 'inherit',
            textDecoration: 'none',
            width: '100%',
            fontSize: 0,
        },
        listItem: {
            fontWeight: 500,
            color: theme.palette.text.secondary,
            '&:hover': {
                background: theme.palette.grey["200"],
            },
        },
        listItemSelected: {
            boxShadow: `inset 4px 0 0 ${theme.palette.primary.dark}`,
            color: theme.palette.text.primary,
        },
        listItemIcon: {
            marginRight: 0,
            minWidth: '32px',
            color: 'inherit',
        },
        listItemTextTypography: {
            fontWeight: 'inherit',
        },
        logoWrapper: {
            width: '100%',
            height: 96,
            padding: theme.spacing(2),
            textAlign: 'center',
        },
        claim: {
            padding: theme.spacing(0, 2),
        }
    }),
);

export default function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const currentPath = useSelector(getCurrentPath);
    const { t } = useTranslation();
    const isAdmin = useSelector(getIsAdmin);

    const isListItemSelected = url => {
        const urlHash = `#${url}`;
        if (
            !currentPath ||
            currentPath.startsWith('#/&state=')
        ) {
            return urlHash === '#/';
        }

        if (urlHash === '#/') {
            return currentPath === '#/';
        }

        return currentPath.indexOf(urlHash) === 0;
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerClose = () => {
        setMobileOpen(false);
    };

    const HideOnScroll = props => {
        const { children } = props;
        const trigger = useScrollTrigger();

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        );
    };

    const drawer = (
        <div className={classes.sidebar}>
            <Link className={classes.logoWrapper} to="/">
                <Logo/>
            </Link>
            <List disablePadding className={classes.grow}>
                <Link className={classes.link} to={'/'} key={'/'} >
                    <ListItem classes={{root: classes.listItem, selected: classes.listItemSelected}} 
                        selected={isListItemSelected('/')}
                        onClick={handleDrawerClose}>
                        <ListItemText primary={t('home:title')} primaryTypographyProps={{variant: 'subtitle2', classes: {root: classes.listItemTextTypography}}} />
                    </ListItem>
                </Link>
                <Link className={classes.link} to={'/accounting'} key={'accounting'} >
                    <ListItem classes={{root: classes.listItem, selected: classes.listItemSelected}} 
                        selected={isListItemSelected('/accounting') || isListItemSelected('/tours')}
                        onClick={handleDrawerClose}>
                        <ListItemText primary={t('accounting:title')} primaryTypographyProps={{variant: 'subtitle2', classes: {root: classes.listItemTextTypography}}} />
                    </ListItem>
                </Link>
                <Link className={classes.link} to={'/imprint'} key={'imprint'} >
                    <ListItem classes={{root: classes.listItem, selected: classes.listItemSelected}} 
                        selected={isListItemSelected('/imprint')}
                        onClick={handleDrawerClose}>
                        <ListItemText primary={t('imprint:title')} primaryTypographyProps={{variant: 'subtitle2', classes: {root: classes.listItemTextTypography}}} />
                    </ListItem>
                </Link>
                <Link className={classes.link} to={'/dataPolicy'} key={'dataPolicy'} >
                    <ListItem classes={{root: classes.listItem, selected: classes.listItemSelected}} 
                        selected={isListItemSelected('/dataPolicy')}
                        onClick={handleDrawerClose}>
                        <ListItemText primary={t('dataPolicy:title')} primaryTypographyProps={{variant: 'subtitle2', classes: {root: classes.listItemTextTypography}}} />
                    </ListItem>
                </Link>
                <Link className={classes.link} to={'/userManagement'} key={'userManagement'} style={{ display: !isAdmin && 'none' }}>
                    <ListItem classes={{root: classes.listItem, selected: classes.listItemSelected}} 
                        selected={isListItemSelected('/userManagement')}
                        onClick={handleDrawerClose}>
                        <ListItemText primary={t('userManagement:title')} primaryTypographyProps={{variant: 'subtitle2', classes: {root: classes.listItemTextTypography}}} />
                    </ListItem>
                </Link>
            </List>
            <Divider/>
            <Typography variant="caption" color="textSecondary" className={classes.claim}><Claim/></Typography>
        </div>
    );

    return (
        <div className={classes.grow}>
            <HideOnScroll>
                <AppBar elevation={0} position="fixed" color="default" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton} >
                            <MenuIcon />
                        </IconButton>
                        {/*<Typography variant="h5" component="h1" color="primary">{currentPageName && currentPageName}</Typography>*/}
                        <div className={classes.grow} />
                        <LanguageSwitcher/>
                        <LogOutButton/>
                    </Toolbar>
                    <Divider/>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
            <nav className={classes.drawer} aria-label="Navigation">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                {props.children}
            </main>
        </div>
    );
}