import { 
    INQUIRY_TYPE_UPDATED,
    COUNTRY_FROM_UPDATED,
    COUNTRY_TO_UPDATED,
    PLZ_FROM_UPDATED,
    PLZ_TO_UPDATED,
    CITY_FROM_UPDATED,
    CITY_TO_UPDATED,
    SENDUNGSART_UPDATED,
    WEIGHT_UPDATED,
    DESCRIPTION_UPDATED,
    FREQUENCY_UPDATED,
    FREQUENCY_ADDITIONAL_INFO_UPDATED,
    VEHICLE_TYPE_UPDATED,
    SIDENOTE_UPDATED,
    KLIMANEUTRAL_UPDATED,
    FI_SALUTATION_UPDATED,
    FI_COMPANY_NAME_UPDATED,
    FI_COUNTRY_UPDATED,
    FI_PLZ_UPDATED,
    FI_CITY_UPDATED,
    FI_EMAIL_UPDATED,
    FI_FIRST_NAME_UPDATED,
    FI_LAST_NAME_UPDATED,
    FI_PHONE_UPDATED,
    FI_NEW_FI_ERROR,
    FI_NEW_FI,
    FI_NEW_FI_SUCCESS,
    FI_START_NEW
 } from '../actionTypes';
 import axios from 'axios';

export const updateInquiryType = type => dispatch => dispatch({
    type: INQUIRY_TYPE_UPDATED,
    payload: { inquiryType: type }
})

export const updateCountryFrom = country => dispatch => dispatch({
    type: COUNTRY_FROM_UPDATED,
    payload: { country }
})

export const updateCountryTo = country => dispatch => dispatch({
    type: COUNTRY_TO_UPDATED,
    payload: { country }
})

export const updatePlzFrom = plz => dispatch => dispatch({
    type: PLZ_FROM_UPDATED,
    payload: { plz }
})


export const updatePlzTo = plz => dispatch => dispatch({
    type: PLZ_TO_UPDATED,
    payload: { plz }
})

export const updateCityFrom = city => dispatch => dispatch({
    type: CITY_FROM_UPDATED,
    payload: { city }
})

export const updateCityTo = city => dispatch => dispatch({
    type: CITY_TO_UPDATED,
    payload: { city }
})

export const updateSendungsart = sendungsart => dispatch => dispatch({
    type: SENDUNGSART_UPDATED,
    payload: { sendungsart }
})

export const updateWeight = weight => dispatch => dispatch({
    type: WEIGHT_UPDATED,
    payload: { weight }
})

export const updateDescription = description => dispatch => dispatch({
    type: DESCRIPTION_UPDATED,
    payload: { description }
})

export const updateFrequency = frequency => dispatch => dispatch({
    type: FREQUENCY_UPDATED,
    payload: { frequency }
})

export const updateFrequencyAdditionalInfo = info => dispatch => dispatch({
    type: FREQUENCY_ADDITIONAL_INFO_UPDATED,
    payload: { info }
})

export const updateVehicleType = type => dispatch => dispatch({
    type: VEHICLE_TYPE_UPDATED,
    payload: { type }
})

export const updateSidenote = sidenote => dispatch => dispatch({
    type: SIDENOTE_UPDATED,
    payload: { sidenote }
})

export const updateKlimaneutral = klimaneutral => dispatch => dispatch({
    type: KLIMANEUTRAL_UPDATED,
    payload: { klimaneutral }
})

export const updateSalutation = salutation => dispatch => dispatch({
    type: FI_SALUTATION_UPDATED,
    payload: { salutation }
})

export const updateFirstName = firstName => dispatch => dispatch({
    type: FI_FIRST_NAME_UPDATED,
    payload: { firstName }
})

export const updateLastName = lastName => dispatch => dispatch({
    type: FI_LAST_NAME_UPDATED,
    payload: { lastName }
})

export const updateCompanyName = companyName => dispatch => dispatch({
    type: FI_COMPANY_NAME_UPDATED,
    payload: { companyName }
})

export const updateCountry = country => dispatch => dispatch({
    type: FI_COUNTRY_UPDATED,
    payload: { country }
})

export const updatePlz = plz => dispatch => dispatch({
    type: FI_PLZ_UPDATED,
    payload: { plz }
})

export const updateCity = city => dispatch => dispatch({
    type: FI_CITY_UPDATED,
    payload: { city }
})

export const updateEmail = email => dispatch => dispatch({
    type: FI_EMAIL_UPDATED,
    payload: { email }
})

export const updatePhone = phone => dispatch => dispatch({
    type: FI_PHONE_UPDATED,
    payload: { phone }
})

export const startNewFrightInquiry = () => dispatch => dispatch({ type: FI_START_NEW })

export const submitNewFrightInquiry = (frightInquiryData) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FI_NEW_FI, 
                payload: frightInquiryData
            })
    
            axios.post(`${process.env.REACT_APP_API_URL}/new-fright-inquiry`, frightInquiryData)
                .then((response) => {
                    dispatch({
                        type: FI_NEW_FI_SUCCESS, 
                        payload: {
                            id: response.data.id
                        }
                    })
                    resolve(response.data.id);
                })
                .catch(error => {
                    dispatch({type: FI_NEW_FI_ERROR, payload: {error}})
                    reject();
                });
        })
    }
}