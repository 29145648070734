import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { requestCustomerToursHeader } from '../redux/actions/toursActions';
import { getUserId, getIsAdmin } from '../redux/selectors/authSelectors';
import { useTranslation } from 'react-i18next';
import Error from '../components/Error';
import { getToursHeader } from '../redux/selectors/toursSelectors';
import MaterialTable from 'material-table';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    }
}));

const Accounting = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const customerId = useSelector(getUserId);
    const { t } = useTranslation();
    const tours = useSelector(getToursHeader);
    const [redirectToDetailPage, setRedirectToDetailPage] = useState(false);
    const [filter, setFilter] = useState('open');
    const isAdmin = useSelector(getIsAdmin);

    useEffect(() => {
        customerId && dispatch(requestCustomerToursHeader({customerId: customerId, isAdmin}));
        // eslint-disable-next-line
    }, [customerId]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    if (customerId === undefined) {
        return <Error message={t('error:noCustomerId')}/>
    }
    
    if (redirectToDetailPage) {
        return <Redirect push to={`/tours/${redirectToDetailPage}`} />
    }

    return (
        <>
            <FormControl className={styles.formControl}>
                <InputLabel id="filterTour">{t('tours:filterTours')}</InputLabel>
                <Select
                    value={filter}
                    onChange={handleFilterChange}>
                    <MenuItem value={'open'}>{t('status:open')}</MenuItem>
                    <MenuItem value={'done'}>{t('status:done')}</MenuItem>
                </Select>
            </FormControl>

            <MaterialTable
                columns={[
                    {title: t('tours:id'), field: 'FAHRTNR'},
                    {title: t('tours:plateA'), field: 'TRUCKA'},
                    {title: t('tours:plateB'), field: 'TRUCKB'},
                ]}
                data={tours.filter(t => filter === 'open' ? t.STATUS !== 3 : t.STATUS === 3)}
                title={t('tours:tours')}
                options={{
                    headerStyle: {
                        paddingLeft: 16
                    }}
                }
                actions={[{
                        icon: 'search',
                        tooltip: 'Details',
                        onClick: (event, rowData) => {
                            setRedirectToDetailPage(rowData['FAHRTNR']);
                        }
                    }]} 
            />
        </>
    )
}

export default Accounting;