export const getUserId = store => store.Auth.userId;

export const getLoginError = store => store.Auth.loginError;

export const getCreateUserError = store => store.Auth.createUserError;

export const getIsAuthenticated = store => store.Auth.authenticated;

export const getIsAdmin = store => store.Auth.isAdmin;

export const getUsername = store => store.Auth.username;

export const getUsers = store => store.Auth.users;