import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import clsx from 'clsx';
import Snackbar from "@material-ui/core/Snackbar";
import {getSnackbarMsgs} from "../../redux/selectors/snackbarSelectors";
import {createStyles, makeStyles} from "@material-ui/core";
import {snackbarMsgDisplayed} from "../../redux/actions/snackbarActions";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import {amber, green, orange} from '@material-ui/core/colors';
import SnackbarContent from "@material-ui/core/SnackbarContent";

const useStyles = makeStyles((theme) =>
        createStyles({
            success: {
                backgroundColor: green[600],
            },
            error: {
                backgroundColor: theme.palette.error.dark,
            },
            info: {
                backgroundColor: orange[500],
            },
            warning: {
                backgroundColor: amber[700],
            },
            icon: {
                fontSize: 20,
            },
            iconVariant: {
                opacity: 0.9,
                marginRight: theme.spacing(1),
            },
            close: {
                padding: theme.spacing(0.5),
            },
            message: {
                display: 'flex',
                alignItems: 'center',
            },
        }),
    );

const GlobalSnackbar = () => {
    const dispatch = useDispatch();
    const msgs = useSelector(getSnackbarMsgs);
    const [lastMsg, setLastMsg] = useState({uid: '', msg: '', type: 'info'});
    const classes = useStyles();

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    // If no message is in the list, return the last message again
    // This is needed to keep the message visible while snackbar fades out
    const currentMsg = msgs ? msgs[0] ? msgs[0] : lastMsg : lastMsg;

    function handleClose() {
        setLastMsg(currentMsg);
        dispatch(snackbarMsgDisplayed(currentMsg.uid));
    }

    const Icon = variantIcon[currentMsg.type];

    return <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        open={msgs && msgs.length > 0}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
    ><SnackbarContent
        className={clsx(classes[currentMsg.type])}
        message={<span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {currentMsg.msg}
        </span>}
        action={[
            <IconButton
                key={"close"}
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={handleClose}
            >
                <CloseIcon className={classes.icon} />
            </IconButton>,
        ]}
    /></Snackbar>
};

export default GlobalSnackbar;
