import { 
    INQUIRY_TYPE_UPDATED,
    COUNTRY_FROM_UPDATED,
    COUNTRY_TO_UPDATED,
    PLZ_FROM_UPDATED,
    PLZ_TO_UPDATED,
    CITY_FROM_UPDATED,
    CITY_TO_UPDATED,
    SENDUNGSART_UPDATED,
    WEIGHT_UPDATED,
    DESCRIPTION_UPDATED,
    FREQUENCY_UPDATED,
    FREQUENCY_ADDITIONAL_INFO_UPDATED,
    VEHICLE_TYPE_UPDATED,
    SIDENOTE_UPDATED,
    KLIMANEUTRAL_UPDATED,
    FI_SALUTATION_UPDATED,
    FI_COMPANY_NAME_UPDATED,
    FI_COUNTRY_UPDATED,
    FI_PLZ_UPDATED,
    FI_CITY_UPDATED,
    FI_EMAIL_UPDATED,
    FI_FIRST_NAME_UPDATED,
    FI_LAST_NAME_UPDATED,
    FI_PHONE_UPDATED,
    FI_NEW_FI_ERROR,
    FI_NEW_FI_SUCCESS,
    FI_START_NEW
 } from '../actionTypes';

const initialState = {
    type: 'business',
    cityFrom: '',
    cityTo: '',
    plzFrom: '',
    plzTo: '',
    countryFrom: '',
    countryTo: '',
    sendungsart: '',
    weight: '',
    description: '',
    frequency: '',
    frequencyAdditionalInfo: '',
    vehicleType: '',
    sidenote: '',
    klimaneutral: true,
    salutation: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    plz: '',
    city: '',
    email: '',
    phone: '',
    error: false,
    newFrightInquiryId: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INQUIRY_TYPE_UPDATED: {
            return {
                ...state,
                type: action.payload.inquiryType
            }
        }

        case COUNTRY_FROM_UPDATED: {
            return {
                ...state,
                countryFrom: action.payload.country
            }
        }

        case COUNTRY_TO_UPDATED: {
            return {
                ...state,
                countryTo: action.payload.country
            }
        }

        case PLZ_FROM_UPDATED: {
            return {
                ...state,
                plzFrom: action.payload.plz
            }
        }

        case PLZ_TO_UPDATED: {
            return {
                ...state,
                plzTo: action.payload.plz
            }
        }

        case CITY_FROM_UPDATED: {
            return {
                ...state,
                cityFrom: action.payload.city
            }
        }

        case CITY_TO_UPDATED: {
            return {
                ...state,
                cityTo: action.payload.city
            }
        }

        case SENDUNGSART_UPDATED: {
            return {
                ...state,
                sendungsart: action.payload.sendungsart
            }
        }

        case WEIGHT_UPDATED: {
            return {
                ...state,
                weight: action.payload.weight
            }
        }

        case DESCRIPTION_UPDATED: {
            return {
                ...state,
                description: action.payload.description
            }
        }

        case FREQUENCY_UPDATED: {
            return {
                ...state,
                frequency: action.payload.frequency
            }
        }

        case FREQUENCY_ADDITIONAL_INFO_UPDATED: {
            return {
                ...state,
                frequencyAdditionalInfo: action.payload.info
            }
        }

        case VEHICLE_TYPE_UPDATED: {
            return {
                ...state,
                vehicleType: action.payload.type
            }
        }

        case SIDENOTE_UPDATED: {
            return {
                ...state,
                sidenote: action.payload.sidenote
            }
        }

        case KLIMANEUTRAL_UPDATED: {
            return {
                ...state,
                klimaneutral: action.payload.klimaneutral
            }
        }

        case FI_SALUTATION_UPDATED: {
            return {
                ...state,
                salutation: action.payload.salutation
            }
        }

        case FI_FIRST_NAME_UPDATED: {
            return {
                ...state,
                firstName: action.payload.firstName
            }
        }

        case FI_LAST_NAME_UPDATED: {
            return {
                ...state,
                lastName: action.payload.lastName
            }
        }

        case FI_COMPANY_NAME_UPDATED: {
            return {
                ...state,
                companyName: action.payload.companyName
            }
        }

        case FI_COUNTRY_UPDATED: {
            return {
                ...state,
                country: action.payload.country
            }
        }

        case FI_PLZ_UPDATED: {
            return {
                ...state,
                plz: action.payload.plz
            }
        }

        case FI_CITY_UPDATED: {
            return {
                ...state,
                city: action.payload.city
            }
        }

        case FI_EMAIL_UPDATED: {
            return {
                ...state,
                email: action.payload.email
            }
        }

        case FI_PHONE_UPDATED: {
            return {
                ...state,
                phone: action.payload.phone
            }
        }

        case FI_NEW_FI_ERROR: {
            return {
                ...state,
                error: true
            }
        }

        case FI_NEW_FI_SUCCESS: {
            return {
                ...state,
                error: false,
                newFrightInquiryId: action.payload.id
            }
        }

        case FI_START_NEW: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}