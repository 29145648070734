import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InquiryType from '../components/FrightInquiry/InquiryType';
import Relation from '../components/FrightInquiry/Relation';
import Description from '../components/FrightInquiry/Description';
import Sustainability from '../components/FrightInquiry/Sustainability';
import PaperSheet from '../components/Paper';
import ContactData from '../components/FrightInquiry/ContactData';
import { getFrightInquiry, getFrightInquiryError, getFrightInquiryNewId } from '../redux/selectors/frightInquirySelectors';
import { useSelector } from 'react-redux';
import { submitNewFrightInquiry, startNewFrightInquiry } from '../redux/actions/frightInquiryActions';
import { useDispatch } from 'react-redux';
import Overview from '../components/FrightInquiry/Overview';
import withWidth from '@material-ui/core/withWidth';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-194258321-1');

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const getSteps = () => ['Art der Anfrage', 'Relation', 'Sendungsbeschreibung', 'Nachhaltigkeit', 'Kontaktdaten'];

const getStepContent = stepIndex => {
    switch (stepIndex) {
        case 0:
            return <InquiryType/>;
        case 1:
            return <Relation/>;
        case 2:
            return <Description/>;
        case 3:
            return <Sustainability/>;
        case 4:
            return <ContactData/>;
        default:
            return 'Multi step form error';
    }
}

const FrightInquiry = (props) => {
    const { t, i18n } = useTranslation('fi');
    const changeLanguage = (lng) => i18n.changeLanguage(lng);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const FID = useSelector(getFrightInquiry);
    const error = useSelector(getFrightInquiryError);
    const newFrightInquiryId = useSelector(getFrightInquiryNewId);

    useEffect(() => {
        ReactGA.event({
            category: 'Frachtanfrage',
            action: 'gestartet',
            value: 1,
            label: 'Frachtanfrage gestartet'
        });
        if (props.language) changeLanguage(props.language);
    }, []);

    useEffect(() => {
        if (newFrightInquiryId) setActiveStep(0)
    }, [newFrightInquiryId]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit = () => {
        ReactGA.event({
            category: 'Frachtanfrage',
            action: 'gesendet',
            value: 1,
            label: 'Frachtanfrage gesendet'
        });
        dispatch(submitNewFrightInquiry(FID))
    }

    const handleStartNew = () => dispatch(startNewFrightInquiry())

    // Required fields for fright inquiry
    const getSubmitAvailable = () => {
        switch (activeStep) {
            case 0:
                return FID.type === 'business';
            case 1:
                return FID.countryFrom && FID.plzFrom && FID.cityFrom && FID.countryTo && FID.plzTo && FID.cityTo;
            case 2:
                return FID.sendungsart && FID.weight && FID.description;
            case 3:
                return true;
            case 4:
                return FID.salutation && FID.firstName && FID.lastName && FID.companyName && FID.email && FID.phone;
            default: 
                return false;
        }
    };

    if (newFrightInquiryId) {
        return (
            <PaperSheet>
                <div className={classes.root}>
                    <Typography className={classes.instructions}>{t('successMessage')} {newFrightInquiryId}</Typography>
                    <Button variant='contained' color='primary' onClick={handleStartNew}>{t('startNew')}</Button>
                </div>
            </PaperSheet>
        )
    }
    
    return (
        <PaperSheet>
            <div className={classes.root}>
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel={(props.width === 'xs' || props.width === 'sm') ? false : true}
                    orientation={(props.width === 'xs' || props.width === 'sm') ? 'vertical' : 'horizontal'}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{t(label)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>{t('finishMessage')}</Typography>
                        <Overview fid={FID}/>
                        {error && <Typography className={classes.instructions} color='error' >{t('errorMessage')}</Typography>}
                        <Button variant='contained' color='primary' onClick={handleSubmit}>{t('submit')}</Button>
                        <Button onClick={handleReset}>{t('reset')}</Button>
                    </div>
                    ) : (
                    <div>
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                {t('back')}
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNext} disabled={!getSubmitAvailable()}>
                                {activeStep === steps.length - 1 ? t('finish') : t('next')}
                            </Button>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </PaperSheet>
    )
}

export default withWidth()(FrightInquiry);