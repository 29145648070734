import React, { useEffect, useState } from 'react';
import { getImprintContent } from '../redux/selectors/contentSelectors';
import { requestImprintContent } from '../redux/actions/contentActions';
import { useSelector, useDispatch } from 'react-redux';
import PaperSheet from '../components/Paper';

const Imprint = () => {
    const content = useSelector(getImprintContent);
    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        requestImprintContent()(dispatch).then(response => setDataLoaded(true))
        // eslint-disable-next-line
    }, []);
    
    return (
        <>
            {(dataLoaded && content.content.length > 0) && <PaperSheet>
                <div dangerouslySetInnerHTML={{__html: content.content[0].CONTENT}}/>
            </PaperSheet>}
        </>
    )
}

export default Imprint;