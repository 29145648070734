import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCountryFrom, getCountryTo, getPlzFrom, getPlzTo, getCityFrom, getCityTo } from '../../redux/selectors/frightInquirySelectors';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';
import { updateCountryFrom, updateCountryTo, updatePlzTo, updatePlzFrom, updateCityFrom, updateCityTo } from '../../redux/actions/frightInquiryActions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import { countries } from '../../helpers/countries';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3),
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        minWidth: 200
    },
    formGroup: {
        maxWidth: '761px'
    }
}));

const InquiryType = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const countryFrom = useSelector(getCountryFrom);
    const countryTo = useSelector(getCountryTo);
    const plzFrom = useSelector(getPlzFrom);
    const plzTo = useSelector(getPlzTo);
    const cityFrom = useSelector(getCityFrom);
    const cityTo = useSelector(getCityTo);
    const dispatch = useDispatch();

    const handleCountryFromChange = event => dispatch(updateCountryFrom(event.target.value));
    const handleCountryToChange = event => dispatch(updateCountryTo(event.target.value));
    const handlePlzFromChange = event => dispatch(updatePlzFrom(event.target.value));
    const handlePlzToChange = event => dispatch(updatePlzTo(event.target.value));
    const handleCityFromChange = event => dispatch(updateCityFrom(event.target.value));
    const handleCityToChange = event => dispatch(updateCityTo(event.target.value));

    return <div className={classes.root}>
        <div>
            <FormLabel>{t('fi:from')}:</FormLabel>
            <FormGroup row className={classes.formGroup}>
                <FormControl variant="outlined" className={classes.formControl} required>
                    <InputLabel id="countryFrom">{t('fi:country')}</InputLabel>
                    <Select
                        className={classes.formElement}
                        id="countryFrom"
                        value={countryFrom}
                        onChange={handleCountryFromChange}
                        label="countryFrom"
                        labelWidth={56}
                    >
                        {countries.map(c => <MenuItem value={c} key={c}>{c}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField variant="outlined" label={t('fi:plz')} value={plzFrom} onChange={handlePlzFromChange} required />    
                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField variant="outlined" label={t('fi:city')} value={cityFrom} onChange={handleCityFromChange} required />    
                </FormControl>
            </FormGroup>
        </div>

        <div>
            <FormLabel>{t('fi:to')}:</FormLabel>
            <FormGroup row>
                <FormControl variant="outlined" className={classes.formControl} required>
                    <InputLabel id="countryTo">{t('fi:country')}</InputLabel>
                    <Select
                        id="countryTo"
                        value={countryTo}
                        onChange={handleCountryToChange}
                        label="countryTo"
                        labelWidth={56}
                    >
                        {countries.map(c => <MenuItem value={c} key={c}>{c}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField variant="outlined" label={t('fi:plz')} value={plzTo} onChange={handlePlzToChange} required />    
                </FormControl>
                
                <FormControl className={classes.formControl}>
                    <TextField variant="outlined" label={t('fi:city')} value={cityTo} onChange={handleCityToChange} required />    
                </FormControl>
            </FormGroup>
        </div>
    </div>
}

export default InquiryType;