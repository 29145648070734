import React from 'react';
import Typography from '@material-ui/core/Typography';
import ErrorOutlined from '@material-ui/icons/ErrorOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        padding: theme.spacing(2),
    }
}));

const Error = ({message}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.container}>
            <div className={classes.root}>
                <ErrorOutlined className={classes.icon}/>
                <Typography variant="h5" color="error">
                    {message}
                </Typography>
                
            </div>
            <Typography variant="body1">{t('basic:mail')}: m.geschwill@alpensped.de</Typography>
            <Typography variant="body1">{t('basic:phone')}: +49 (0)621 84408-54</Typography>
        </Paper>
    )
}

export default Error;