import React from 'react';
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getIsAdmin } from '../../redux/selectors/authSelectors';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAdmin = useSelector(getIsAdmin);

    return (
        <Route {...rest} render={(props) => (
            isAdmin && <Component {...props} />
        )} />
    )
}

export default PrivateRoute;