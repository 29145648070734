import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Login from './components/Login';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { Provider } from 'react-redux';
import './i18n/i18n';
import Cookies from 'js-cookie';
import Axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import FrightInquiry from './pages/FrightInquiry';
import { parseQuery } from './helpers/parseQueryString';
import theme from './theme';
import { ThemeProvider } from '@material-ui/styles';

const userId = Cookies.get('userId');
const username = Cookies.get('username');

const queryParams = parseQuery(window.location.search);

if (window.location.pathname.includes('frightInquiry')) {
    ReactDOM.render(<Provider store={store}>
        <ThemeProvider theme={theme}>
            <FrightInquiry language={queryParams.language ? queryParams.language : 'de'} />
        </ThemeProvider>
    </Provider>, document.getElementById('frightInquiry'))
} else {
    if (!userId || !username) {
        ReactDOM.render(<Provider store={store}>
            <CssBaseline />
            <Login />
        </Provider>, document.getElementById('root'));
    } else {
        // let test = document.createElement('link');
        // test.type = 'text/css';
        // test.rel = 'stylesheet';
        // test.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
        // document.head.append(test);
        Axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, {
            userId,
            username
        }).then(response => {
            store.dispatch({
                type: 'AUTHENTICATION_SUCCESS', payload: {
                    userId: userId,
                    username: username,
                    isAdmin: response.data.isAdmin
                }
            })
            ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
        }).catch(err => {
            store.dispatch({ type: 'AUTHENTICATION_ERROR' });
            ReactDOM.render(<Provider store={store}>
                <CssBaseline />
                <Login />
            </Provider>, document.getElementById('root'));
        })
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
