import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { logOut } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LogOutButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <IconButton
            onClick={() => dispatch(logOut())}
            title={t('actions:logout')}>
            <PowerSettingsNew />
        </IconButton>
    );
};

export default LogOutButton;