export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_ERROR = 'TOKEN_REFRESH_ERROR';
export const LOGOUT ='LOGOUT';
export const APP_STARTED = 'APP_STARTED';
export const ROUTE_CHANGED = 'ROUTE_CHANGED';
export const RECHNUNG_UPLOADED = 'RECHNUNG_UPLOADED';
export const FRACHTBRIEF_UPLOADED = 'FRACHTBRIEF_UPLOADED';
export const LIEFERSCHEIN_UPLOADED = 'LIEFERSCHEIN_UPLOADED';
export const LAUFZETTEL_UPLOADED = 'LAUFZETTEL_UPLOADED';
export const FILES_UPLOADED_TO_BACKEND = 'FILES_UPLOADED_TO_BACKEND';
export const FILES_UPLOADED_TO_BACKEND_SUCCESS = 'FILES_UPLOADED_TO_BACKEND_SUCCESS';
export const FILES_UPLOADED_TO_BACKEND_ERROR = 'FILES_UPLOADED_TO_BACKEND_ERROR';
export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const CUSTOMER_TOURS_HEADER_REQUESTED = 'CUSTOMER_TOURS_HEADER_REQUESTED';
export const CUSTOMER_TOURS_HEADER_RECEIVED = 'CUSTOMER_TOURS_HEADER_RECEIVED';
export const CUSTOMER_TOURS_HEADER_ERROR = 'CUSTOMER_TOURS_HEADER_ERROR';
export const PARCELS_REQUESTED = 'PARCELS_REQUESTED';
export const PARCELS_RECEIVED = 'PARCELS_RECEIVED';
export const PARCELS_ERROR = 'PARCELS_ERROR';
export const HISTORY_CREATED = 'HISTORY_CREATED';
export const HOME_CONTENT_REQUESTED = 'HOME_CONTENT_REQUESTED';
export const HOME_CONTENT_RECEIVED = 'HOME_CONTENT_RECEIVED';
export const HOME_CONTENT_ERROR = 'HOME_CONTENT_ERROR';
export const IMPRINT_CONTENT_REQUESTED = 'IMPRINT_CONTENT_REQUESTED';
export const IMPRINT_CONTENT_RECEIVED = 'IMPRINT_CONTENT_RECEIVED';
export const IMPRINT_CONTENT_ERROR = 'IMPRINT_CONTENT_ERROR';
export const DATA_POLICY_CONTENT_REQUESTED = 'DATA_POLICY_CONTENT_REQUESTED';
export const DATA_POLICY_CONTENT_RECEIVED = 'DATA_POLICY_CONTENT_RECEIVED';
export const DATA_POLICY_CONTENT_ERROR = 'DATA_POLICY_CONTENT_ERROR';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';
export const SNACKBAR_INFO = 'SNACKBAR_INFO';
export const SNACKBAR_MSG = 'SNACKBAR_MSG';
export const SNACKBAR_MSG_DISPLAYED = 'SNACKBAR_MSG_DISPLAYED';
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const USERS_RECEIVED = 'USERS_RECEIVED';
export const USER_DELETED = 'USER_DELETED';
export const BOUND_FILES_REQUIREMENTS_REQUESTED = 'BOUND_FILES_REQUIREMENTS_REQUESTED';
export const BOUND_FILES_REQUIREMENTS_SUCCESS = 'BOUND_FILES_REQUIREMENTS_SUCCESS';
export const BOUND_FILES_REQUIREMENTS_ERROR = 'BOUND_FILES_REQUIREMENTS_ERROR';
export const CLEAR_UPLOADED_FILES = 'CLEAR_UPLOADED_FILES';
export const RECHNUNG_UPLOADED_TO_BACKEND = 'RECHNUNG_UPLOADED_TO_BACKEND';
export const RECHNUNG_UPLOADED_TO_BACKEND_SUCCESS = 'RECHNUNG_UPLOADED_TO_BACKEND_SUCCESS ';
export const RECHNUNG_UPLOADED_TO_BACKEND_ERROR = 'RECHNUNG_UPLOADED_TO_BACKEND_ERROR';
export const CLEAR_UPLOADED_RECHNUNG = 'CLEAR_UPLOADED_RECHNUNG';
export const BUNDLE_UPLOADED = 'BUNDLE_UPLOADED';
export const BUNDLE_UPLOADED_TO_BACKEND = 'BUNDLE_UPLOADED_TO_BACKEND';
export const BUNDLE_UPLOADED_TO_BACKEND_SUCCESS = 'BUNDLE_UPLOADED_TO_BACKEND_SUCCESS';
export const BUNDLE_UPLOADED_TO_BACKEND_ERROR = 'BUNDLE_UPLOADED_TO_BACKEND_ERROR';
export const INQUIRY_TYPE_UPDATED = 'INQUIRY_TYPE_UPDATED';
export const COUNTRY_FROM_UPDATED = 'COUNTRY_FROM_UPDATED';
export const COUNTRY_TO_UPDATED = 'COUNTRY_TO_UPDATED';
export const PLZ_FROM_UPDATED = 'PLZ_FROM_UPDATED';
export const PLZ_TO_UPDATED = 'PLZ_TO_UPDATED';
export const CITY_FROM_UPDATED = 'CITY_FROM_UPDATED';
export const CITY_TO_UPDATED = 'CITY_TO_UPDATED';
export const SENDUNGSART_UPDATED = 'SENDUNGSART_UPDATED';
export const WEIGHT_UPDATED = 'WEIGHT_UPDATED';
export const DESCRIPTION_UPDATED = 'DESCRIPTION_UPDATED';
export const FREQUENCY_UPDATED = 'FREQUENCY_UPDATED';
export const FREQUENCY_ADDITIONAL_INFO_UPDATED = 'FREQUENCY_ADDITIONAL_INFO_UPDATED';
export const VEHICLE_TYPE_UPDATED = 'VEHICLE_TYPE_UPDATED';
export const SIDENOTE_UPDATED = 'SIDENOTE_UPDATED';
export const KLIMANEUTRAL_UPDATED = 'KLIMANEUTRAL_UPDATED';
export const FI_SALUTATION_UPDATED = 'FI_SALUTATION_UPDATED';
export const FI_FIRST_NAME_UPDATED = 'FI_FIRST_NAME_UPDATED';
export const FI_LAST_NAME_UPDATED = 'FI_LAST_NAME_UPDATED';
export const FI_COMPANY_NAME_UPDATED = 'FI_COMPANY_NAME_UPDATED';
export const FI_COUNTRY_UPDATED = 'FI_COUNTRY_UPDATED';
export const FI_PLZ_UPDATED = 'FI_PLZ_UPDATED';
export const FI_CITY_UPDATED = 'FI_CITY_UPDATED';
export const FI_EMAIL_UPDATED = 'FI_EMAIL_UPDATED';
export const FI_PHONE_UPDATED = 'FI_PHONE_UPDATED';
export const FI_NEW_FI = 'FI_NEW_FI';
export const FI_NEW_FI_SUCCESS = 'FI_NEW_FI_SUCCESS';
export const FI_NEW_FI_ERROR = 'FI_NEW_FI_ERROR';
export const FI_START_NEW = 'FI_START_NEW';