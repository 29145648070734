import { 
    RECHNUNG_UPLOADED, 
    FRACHTBRIEF_UPLOADED, 
    LIEFERSCHEIN_UPLOADED, 
    LAUFZETTEL_UPLOADED, 
    FILES_UPLOADED_TO_BACKEND,
    FILES_UPLOADED_TO_BACKEND_ERROR,
    FILES_UPLOADED_TO_BACKEND_SUCCESS,
    BOUND_FILES_REQUIREMENTS_SUCCESS,
    CLEAR_UPLOADED_FILES,
    BUNDLE_UPLOADED
 } from '../actionTypes';

const initialState = {
    error: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RECHNUNG_UPLOADED: {
            state.rechnung = action.payload;
            return state;
        }

        case FRACHTBRIEF_UPLOADED: {
            state.frachtbrief = action.payload;
            return state;
        }

        case LIEFERSCHEIN_UPLOADED: {
            state.lieferschein = action.payload;
            return state;
        }

        case LAUFZETTEL_UPLOADED: {
            state.laufzettel = action.payload;
            return state;
        }

        case BUNDLE_UPLOADED: {
            state.bundle = action.payload;
            return state;
        }

        case FILES_UPLOADED_TO_BACKEND: {
            return state;
        }

        case FILES_UPLOADED_TO_BACKEND_SUCCESS: {
            state = initialState;
            return state;
        }

        case FILES_UPLOADED_TO_BACKEND_ERROR: {
            state.error = true;
            return state;
        }

        case BOUND_FILES_REQUIREMENTS_SUCCESS: {
            state.boundFilesRequirements = action.payload;
            return state;
        }


        case CLEAR_UPLOADED_FILES: {
            state.rechnung = null;
            state.lieferschein = null;
            state.frachtbrief = null;
            state.laufzettel = null;
            state.bundle = null;
            return state;
        }

        default: {
            return state;
        }
    }
}