import React, { useState } from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(2),
    }
}));

const LinkButton = ({link, children}) => {
    const [redirect, setRedirect] = useState(false);
    const classes = useStyles();

    const goBack = () => setRedirect(link);

    if (redirect) return <Redirect push to={link}/>

    return (
        <Button variant="outlined" onClick={goBack} className={classes.button}>
            <ArrowBack />
            {children}
	    </Button>
    )
}

export default LinkButton;