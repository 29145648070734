import React from 'react';
import { useSelector } from 'react-redux';
import { getKlimaneutral } from '../../redux/selectors/frightInquirySelectors';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux';
import { updateKlimaneutral } from '../../redux/actions/frightInquiryActions';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const InquiryType = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const klimaneutral = useSelector(getKlimaneutral);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        dispatch(updateKlimaneutral(event.target.name === 'klimaneutral'))
    };

    return <span>
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{t('fiSustainability:header')}</FormLabel>
            <FormHelperText>{t('fiSustainability:body')}</FormHelperText>
            <FormGroup>
                <FormControlLabel
                    key={1}
                    control={<Checkbox checked={klimaneutral} onChange={handleChange} name={'klimaneutral'} color='primary'/>}
                    label={t('basic:yes')}
                />
                <FormControlLabel
                    key={2}
                    control={<Checkbox checked={!klimaneutral} onChange={handleChange} name={'nichtKlimaneutral'} color='primary'/>}
                    label={t('basic:no')}
                />
            </FormGroup>
        </FormControl>
    </span>
}

export default InquiryType;