export const getFrightInquiryType = state => state.FrightInquiry.type;
export const getCountryFrom = state => state.FrightInquiry.countryFrom;
export const getCountryTo = state => state.FrightInquiry.countryTo;
export const getPlzFrom = state => state.FrightInquiry.plzFrom;
export const getPlzTo = state => state.FrightInquiry.plzTo;
export const getCityFrom = state => state.FrightInquiry.cityFrom;
export const getCityTo = state => state.FrightInquiry.cityTo;
export const getSendungsart = state => state.FrightInquiry.sendungsart;
export const getWeight = state => state.FrightInquiry.weight;
export const getDescription = state => state.FrightInquiry.description;
export const getFrequency = state => state.FrightInquiry.frequency;
export const getFrequencyAdditionalInfo = state => state.FrightInquiry.frequencyAdditionalInfo;
export const getVehicleType = state => state.FrightInquiry.vehicleType;
export const getSidenote = state => state.FrightInquiry.sidenote;
export const getKlimaneutral = state => state.FrightInquiry.klimaneutral;
export const getSalutation = state => state.FrightInquiry.salutation;
export const getFirstName = state => state.FrightInquiry.firstName;
export const getLastName = state => state.FrightInquiry.lastName;
export const getCompanyName = state => state.FrightInquiry.companyName;
export const getCountry = state => state.FrightInquiry.country;
export const getPlz = state => state.FrightInquiry.plz;
export const getCity = state => state.FrightInquiry.city;
export const getEmail = state => state.FrightInquiry.email;
export const getPhone = state => state.FrightInquiry.phone;
export const getFrightInquiry = state => state.FrightInquiry;
export const getFrightInquiryError = state => state.FrightInquiry.error;
export const getFrightInquiryNewId = state => state.FrightInquiry.newFrightInquiryId;