import { combineReducers } from "redux";
import Auth from './authReducer';
import App from './appReducer';
import Nav from './navReducer';
import Files from './filesReducer';
import Tours from './toursReducer';
import Content from './contentReducer';
import Snackbar from './snackbarReducer';
import FrightInquiry from './frightInquiryReducer';

export default combineReducers({
    App,
    Auth,
    Nav,
    Files,
    Tours,
    Content,
    Snackbar,
    FrightInquiry
});