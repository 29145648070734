import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { Visibility } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
    pageIndicator: {
        textAlign: 'center',
        margin: `${theme.spacing(1)}px auto`,
    },
    closeThing: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    main: {
        display: 'inline',
    },
    showPreview: {
        margin: theme.spacing(1),
    }
}));

const PdfPreview = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showPreview, setShowPreview] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const handlePageChange = pagenum => setPageNumber(pagenum);
    const handleShowPreview = show => setShowPreview(show);
    const handleClose = () => setShowPreview(false);
 
    return (
        <div className={classes.main} onClick={(e) => e.stopPropagation()}>
            <IconButton
                variant="outlined"
                color="primary"
                className={classes.showPreview}
                onClick={(e) => handleShowPreview(true)}
            >
               <Visibility/>
            </IconButton>
            <Dialog 
                onClose={handleClose} 
                aria-labelledby="pdf-preview-dialog" 
                open={showPreview} 
                onBackdropClick={e => e.stopPropagation()}
                maxWidth="md"
            >
                <DialogTitle>{t('pdf:preview')}</DialogTitle>
                <div className={classes.closeThing} onClick={handleClose}>
                    <IconButton color="primary" aria-label="close dialog" component="span">
                        <Close />
                    </IconButton>
                </div>
                <Document
                    file={props.pdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    >
                    <Page pageNumber={pageNumber} />
                </Document>
                {pageNumber > 1 && <Button onClick={(e) => {
                    e.stopPropagation();
                    handlePageChange(pageNumber - 1);
                }}>{t('pdf:previousPage')}</Button>}
                {pageNumber < numPages && <Button onClick={(e) => {
                    e.stopPropagation();
                    handlePageChange(pageNumber + 1)
                }}>{t('pdf:nextPage')}</Button>}
                <Typography variant="caption" className={classes.pageIndicator}>{t('pdf:page')} {pageNumber} {t('pdf:of')} {numPages}.</Typography>
            </Dialog>
        
        </div>
    );
}

export default PdfPreview;