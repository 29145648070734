import React from 'react';
import i18n from '../../i18n/i18n';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Language from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const LanguageSwitcher = () => {
    const changeLanguage = (lng) => i18n.changeLanguage(lng);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
        <IconButton 
            aria-describedby={id} 
            variant="contained" 
            onClick={handleClick}
            title={t('actions:selectLanguage')}>
            <Language />
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Typography className={classes.typography}>
                <Button onClick={() => changeLanguage('de')}>de</Button>
                <Button onClick={() => changeLanguage('en')}>en</Button>
            </Typography>
        </Popover>
        </div>
    )
};

export default LanguageSwitcher;