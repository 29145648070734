import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FileDropzone from '../components/FileDropzone';
import { useSelector } from 'react-redux';
import { getFrachtbrief, getLieferschein, getLaufzettel, getBoundFilesRequirements } from '../redux/selectors/filesSelectors';
import Button from '@material-ui/core/Button';
import { uploadFilesToBackend, clearUploadedFiles } from '../redux/actions/filesActions';
import { requestParcels } from '../redux/actions/toursActions';
import { getUserId, getIsAdmin } from '../redux/selectors/authSelectors';
import { useTranslation } from 'react-i18next';
import Error from '../components/Error';
import { Typography } from '@material-ui/core';
import { getParcels } from '../redux/selectors/toursSelectors';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import LinkButton from '../components/LinkButton';
import { snackbarError, snackbarSuccess } from '../redux/actions/snackbarActions';

const useStyles = makeStyles(theme => ({
    details: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    status1: {
        textDecoration: 'underline',
        textDecorationColor: '#C45A43'
    },
    status2: {
        textDecoration: 'underline',
        textDecorationColor: '#F6DD0A'
    },
    status3: {
        textDecoration: 'underline',
        textDecorationColor: '#F6DD0A'
    },
    status4: {
        textDecoration: 'underline',
        textDecorationColor: '#13a538'
    }
}));

const Tour = (props) => {
    const frachtbrief = useSelector(getFrachtbrief);
    const lieferschein = useSelector(getLieferschein);
    const laufzettel = useSelector(getLaufzettel);
    const boundFilesRequirements = useSelector(getBoundFilesRequirements);
    const dispatch = useDispatch();
    const [allUploaded, setAllUploaded] = useState(false);
    const customerId = useSelector(getUserId);
    const { t } = useTranslation();
    const parcels = useSelector(getParcels);
    const parcel = parcels.find(parcel => JSON.stringify(parcel.IDWERKLKWC) === props.match.params.parcelId)
    const classes = useStyles();
    const [uploadDone, setUploadDone] = useState(false);
    const isAdmin = useSelector(getIsAdmin);
    
    useEffect(() => {
        dispatch(requestParcels({tourId: props.match.params.tourId, customerId: customerId, isAdmin}));

        return () => dispatch(clearUploadedFiles());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!parcel) return;
        switch(parcel.BOUND) {
            case 'LG': {
                if (frachtbrief) {
                    setAllUploaded(true);
                }
            }
            case 'VG': {
                if (frachtbrief && lieferschein && laufzettel) {
                    setAllUploaded(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [frachtbrief, lieferschein, laufzettel]);

    if (customerId === undefined) {
        return <Error message={t('error:noCustomerId')}/>
    }

    if (parcel === undefined) {
        return <Error message={t('error:parelNotFound')}/>
    }

    const uploadToServer = () => {
        const data = new FormData();
        frachtbrief && frachtbrief.forEach((file) => {
            data.append('frachtbrief', file)
        });
        lieferschein && lieferschein.forEach((file) => {
            data.append('lieferschein', file)
        });
        laufzettel && laufzettel.forEach((file) => {
            data.append('laufzettel', file)
        });
        (uploadFilesToBackend({
            files: data,
            customerId: customerId,
            tourId: props.match.params.tourId,
            parcelId: props.match.params.parcelId
        }))(dispatch).then(() => {
            dispatch(requestParcels({tourId: props.match.params.tourId, customerId: customerId, isAdmin}));
            dispatch(snackbarSuccess(t('parcels:uploadSuccess')))
            setUploadDone(true)
        }).catch(() => {
            dispatch(snackbarError(t('parcels:uploadFailed')))
        });
    }

    const statusClassName = `classes.status${parcel.STATUS}`;

    return (
        <>
            <LinkButton link={`/tours/${props.match.params.tourId}`}>{t('actions:backToTour')}</LinkButton>
            <Typography variant="h3">{`${t('parcels:id')}: ${parcel.IDWERKLKWC}`}</Typography>

            <Paper component="section" className={classes.details}>
                    <Typography variant="h5">
                        <strong>
                        {t('parcels:status')}: <span className={eval(statusClassName)}>{t(`parcels:status${parcel.STATUS}`)}</span>
                        </strong>
                    </Typography>  

                    <Typography variant="h6">
                        {t('tours:id')}: {parcel.FAHRTNR}
                    </Typography>   

                    <hr/>

                    <Typography variant="h6">
                        <strong>{t('parcels:sender')}</strong>
                    </Typography>
                    <Typography variant="body1">
                        {parcel.ABSNAME}
                    </Typography>    
                    <Typography variant="body1">
                        {parcel.ABSPLZ} {parcel.ABSORT} {parcel.ABSLAND}
                    </Typography>

                    <hr/>

                    <Typography variant="h6">
                        <strong>{t('parcels:recipient')}</strong>
                    </Typography>   
                    <Typography variant="body1">
                        {parcel.EMPFNAME}
                    </Typography>   
                    <Typography variant="body1">
                        {parcel.EMPFPLZ} {parcel.EMPFORT} {parcel.EMPFLAND}
                    </Typography>         

                    <hr/>      

                    <Typography variant="h6">
                        <strong>{t('parcels:pickupDate')}:</strong> {new Date(parcel.LADEDATUM).toLocaleString()}
                    </Typography>  
                    <Typography variant="h6">
                        <strong>{t('parcels:deliveryDate')}:</strong> {new Date(parcel.LIEFERDATUM).toLocaleString()}
                    </Typography> 
            </Paper>

            {(!uploadDone && parcel.STATUS === 1) && <>
                {(boundFilesRequirements && !!boundFilesRequirements.find(req => req.BOUND === parcel.BOUND).FB) && 
                    <FileDropzone id={'Frachtbrief'} title={t('documents:waybill')} files={frachtbrief} />}
                {(boundFilesRequirements && !!boundFilesRequirements.find(req => req.BOUND === parcel.BOUND).LS) && 
                    <FileDropzone id={'Lieferschein'} title={t('documents:deliveryNote')} files={lieferschein} />}
                {(boundFilesRequirements && !!boundFilesRequirements.find(req => req.BOUND === parcel.BOUND).LZ) && 
                    <FileDropzone id={'Laufzettel'} title={t('documents:runningNote')} files={laufzettel} />}
            </>}
            {(allUploaded && parcel.STATUS === 1) && <Button variant="contained" 
                color="primary" 
                component="span"
                onClick={uploadToServer}>{t('actions:upload')}
            </Button>}
        </>
    )
}

export default Tour;