import { 
    LOGOUT,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    AUTHENTICATION_ERROR,
    AUTHENTICATION_SUCCESS,
    USERS_RECEIVED,
    USER_DELETED
} from '../actionTypes';
import Axios from 'axios';
import Cookies from 'js-cookie';

export const logOut = () => {
    return dispatch => {
        dispatch({
            type: LOGOUT, 
        });

        Cookies.remove('userId');
        window.location.href = process.env.REACT_APP_BASE_URL;
    }
};

export const logIn = (username, password) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            Axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                username,
                password
            }).then(response => {
                dispatch({type: LOGIN_SUCCESS, payload: {
                    userId: response.data.userId
                }})
                resolve(response.data);
            }).catch(err => {
                dispatch({type: LOGIN_ERROR});
                reject(err);
            })
        });
    }
}

export const authenticate = (userId, username) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            Axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, {
                userId,
                username
            }).then(response => {
                dispatch({type: AUTHENTICATION_SUCCESS, payload: {
                    userId: response.data.userId,
                    username: response.data.username,
                }})
                resolve(response.data);
            }).catch(err => {
                dispatch({type: AUTHENTICATION_ERROR});
                reject(err);
            })
        });
    }
}

export const createUser = (username, password, userId, role = 'customer') => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            Axios.post(`${process.env.REACT_APP_API_URL}/createUser`, {
                username,
                password,
                userId,
                role
            }).then(response => {
                dispatch({type: CREATE_USER_SUCCESS})
                resolve(response);
            }).catch(err => {
                dispatch({type: CREATE_USER_ERROR});
                reject(err);
            })
        })
    }
}

export const getAllUsers = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            Axios.get(`${process.env.REACT_APP_API_URL}/users`).then(response => {
                dispatch({
                    type: USERS_RECEIVED,
                    payload: response.data
                });
                resolve(response.data);
            })
        })
    }
}

export const deleteUser = (username, userId) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            Axios.post(`${process.env.REACT_APP_API_URL}/deleteUser`, {
                username,
                userId
            }).then(response => {
                if (response.data.deleted) {
                    dispatch({
                        type: USER_DELETED,
                        payload: {
                            username,
                            userId
                        }
                    })
                    resolve(response.data);
                } else {
                    reject({deleted: false});
                }
            })
        })
    }
}